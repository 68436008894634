module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/rhoimpact/favicon.png","name":"Rho Impact","short_name":"Rho Impact","start_url":"/","background_color":"#2B2C32","theme_color":"#48a0e3","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ffff215b58e3d74a2b2beca1b24275ef"},
    },{
      plugin: require('../node_modules/@galexc/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_hcAcPjABTgD70SFb6suMGLeuX3ZZ910SQ6oOutyKlw4","apiHost":"https://ph.koi.eco"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
