exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-2023-eco-report-js": () => import("./../../../src/pages/case-studies/2023-eco-report.js" /* webpackChunkName: "component---src-pages-case-studies-2023-eco-report-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-robotic-inspections-in-advancing-sustainability-performance-js": () => import("./../../../src/pages/case-studies/robotic-inspections-in-advancing-sustainability-performance.js" /* webpackChunkName: "component---src-pages-case-studies-robotic-inspections-in-advancing-sustainability-performance-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crane-tool-js": () => import("./../../../src/pages/crane-tool.js" /* webpackChunkName: "component---src-pages-crane-tool-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-esg-policy-js": () => import("./../../../src/pages/esg-policy.js" /* webpackChunkName: "component---src-pages-esg-policy-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koi-js": () => import("./../../../src/pages/koi.js" /* webpackChunkName: "component---src-pages-koi-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-security-policy-js": () => import("./../../../src/pages/security-policy.js" /* webpackChunkName: "component---src-pages-security-policy-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogsTemplate.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-careers-template-js": () => import("./../../../src/templates/careersTemplate.js" /* webpackChunkName: "component---src-templates-careers-template-js" */),
  "component---src-templates-press-release-template-js": () => import("./../../../src/templates/pressReleaseTemplate.js" /* webpackChunkName: "component---src-templates-press-release-template-js" */),
  "component---src-templates-press-releases-template-js": () => import("./../../../src/templates/pressReleasesTemplate.js" /* webpackChunkName: "component---src-templates-press-releases-template-js" */)
}

